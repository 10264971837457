<script lang="ts" setup>
const { open } = useNlcModals();
const loginGuard = useLoginGuard();

const handleClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/coin-drops");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<AHeader class="mini-prize" @click="handleClick">
		<AAnimationJittery class="gift">
			<NuxtImg
				src="/nuxt-img/coin-drops/page-hiw-gift-3.png"
				width="343"
				height="343"
				loading="lazy"
				format="webp"
				alt="gift-base"
			/>
		</AAnimationJittery>
	</AHeader>
</template>

<style lang="scss" scoped>
.mini-prize {
	width: 50px;
	height: 50px;
	padding: 4px;
	border-radius: 8px 0 0 8px;
	box-shadow: 0 0 12px 0 var(--custom-2);

	img {
		width: 100%;
		height: 100%;
	}
}
</style>
